@import url(https://fonts.googleapis.com/css2?family=Roboto);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Nickainley";
  src: local("Nickainley-Normal"),
    url(/static/media/Nickainley-Normal.5bd352df.otf) format("truetype");
}

html {
  height: 100vh;
}
body {
  background: linear-gradient(0deg, #08070d 10%, #1d1a31 80%, #29263a 100%);
  color: "white";
}
iframe {
  width: 375px;
  height: 500px;
  border: 0;
  border-radius: 5px;
}
#iframe-mob {
  -ms-zoom: 0.5;
  -moz-transform: scale(0.5);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.5);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.5);
  -webkit-transform-origin: top left;
  margin-bottom: -250px;
  margin-right: -187px;
}

